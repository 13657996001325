import React from 'react'
import '../Styles/Testimonials.css'
import TestimonialMan1 from '../assets/images/verizonimg.jpg'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import BottomCarousel from '../Components/BottomCarousel'
import TestimonialMan from '../assets/images//testimonials_man.png'
function Testimonials() {
// Accordian Action
var action = 'click';
var speed = "500";


return (
<div className="App">
   <header className="header">
      <section className='backgroung-image-testimonails'>
         <Navbar/>
         {/* <div className='header-about-content'>
            <h1>Testimonials</h1>
         </div> */}
      </section>
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="container">
                  <div className="row">
                     <h5>testimonials</h5>
                     <h2><span>What</span> Client Says</h2>
                     <div class="testimonials-bg">
	<div class="container">
   <div class="row">
		   <ul>
			   <li>
            <div class="box">
					   <p>We entrusted and awarded ATP to BRiX for PAN-india in early 2015.They have excelled by enabling 35+ Extreme Certified Specialist and 40+ extreme certified Expert in the first 18 months of their operation which helped our channel partner in great extended to handle growing business and technical support services.Most importantly , quality delivery with their Brand conscious is excited us. Best wishes to team BRiX.</p>
					   <div class="in-box">
                  <img src={TestimonialMan} alt="Testimonial" />
						   <h4>Regional Director-Channel</h4>
						   <h6>SAARC Region, extreme networks</h6>
					   </div>
				   </div>
			   </li>
			    <li>
				   <div class="box">
					   <p>"Thanks to our team gained invaluable insights and tools for success. Their dedication and expertise made the process seamless and enjoyable. Highly recommended for corporates looking to maximize their knowledge  assets.”</p>
					   <div class="in-box">
                  <img src={TestimonialMan} alt="Testimonial" />
						   {/* <h4>Regional Director-Channel</h4> */}
						   <h6>VERIZON INDIA</h6>
					   </div>
				   </div>
			   </li>
            <li>
				   <div class="box">
					   <p>”BRIX exceeded our expectations in facilitating knowledge exchange for our students. Their professionalism and expertise made the process seamless and efficient.”</p>
					   <div class="in-box">
                  <img src={TestimonialMan} alt="Testimonial2" />
						   <h4>IIITDM - Indian Institute of Information Technology, Design & Manufacturing </h4>
					   </div>
				   </div>
			   </li>
			
		   </ul>
		</div>
       <div class="row">
		   <ul>
			 <li>
            <div class="box">
					   <p>Really Impresed , Subject Matter Expert , methedology and their tools .</p>
					   <div class="in-box">
						<img src={TestimonialMan} alt="Testimonial" />
						   <h4>Manager</h4>
						   <h6>HR, netcon technologies</h6>
					   </div>
				   </div>
			   </li>
            <li>
            <div class="box">
					   <p>Unique delivery by studying participants .Best wishes from us to create brand in the ATD.</p>
					   <div class="in-box">
                  <img src={TestimonialMan} alt="Testimonial" />
						   <h4>Executive</h4>
						   <h6>IT, MuSigma</h6>
					   </div>
				   </div>
			   </li>
			
		   </ul>
		</div>
	</div>
</div>
                  </div>
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default Testimonials;
import React from "react";
import emailjs from "emailjs-com";
import { useState } from "react";
import axios from "axios";

const LearningDevelopmentForm = () => {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState();
  const [message, setMessage] = useState();
  const [designation, setDesignation] = useState();
  const [company, setCompany] = useState();

  const [technology, setTechnology] = useState([]);
  const [domain,setDomain]=useState([]);
  const [development,setDevelopment]=useState([]);
  const [service,setService]=useState([]);

  const handleTechnology = (e) => {
    if (e.target.checked) {
      setTechnology([...technology, e.target.value]);
    } else {
      setTechnology(technology.filter((val) => val !== e.target.value));
    }
  };

  const handleDomain = (e) => {
   if (e.target.checked) {
     setDomain([...domain, e.target.value]);
   } else {
     setDomain(domain.filter((val) => val !== e.target.value));
   }
 };

 const handleDevelopment = (e) => {
   if (e.target.checked) {
     setDevelopment([...development, e.target.value]);
   } else {
     setDevelopment(development.filter((val) => val !== e.target.value));
   }
 };

 const handleService = (e) => {
   if (e.target.checked) {
     setService([...service, e.target.value]);
   } else {
     setService(service.filter((val) => val !== e.target.value));
   }
 };


  const handleSubmit = async () => {
    console.log(name);
    await axios.post("http://localhost:5000/api/learning", {
      name: name,
      mobile: mobile,
      email: email,
      message: message,
      designation: designation,
      company: company,
      technology: technology,
      domain:domain,
      development:development,
      service:service,

    });
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    designation: "",
    company: "",
    technology: [],
    domain: [],
    development: [],
    service: [],
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e, field) => {
    if (e.target.checked) {
      setFormData({ ...formData, [field]: [...formData[field], e.target.value] });
    } else {
      setFormData({
        ...formData,
        [field]: formData[field].filter((val) => val !== e.target.value),
      });
    }
  };


  return (
    <section className="Program-form">
    <div className="programForm">
      <div className="container">
        <h2>
          <span>To Know More</span> About The Program
        </h2>
        <p>
          Please fill the following details. One of our subject matter <br />
          experts will revert to you soon.
        </p>
        <form>
        <div className="programFormInner ">
              {/* <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    placeholder="Name*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="designation"
                    onChange={handleChange}
                    placeholder="Designation*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="company"
                    onChange={handleChange}
                    placeholder="Company"
                    className="text-input form-control"
                    required
                  />
                </div>
              </div> */}
              <div className="">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    placeholder="Name*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="designation"
                    onChange={handleChange}
                    placeholder="Designation*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="company"
                    onChange={handleChange}
                    placeholder="Company"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group ">
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="Email*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group ">
                  <input
                    type="text"
                    name="mobile"
                    pattern="[0-9]{10}"
                    maxLength="10"
                    onChange={handleChange}
                    placeholder="Phone Number*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                   type="text"
                    name="message"
                    onChange={handleChange}
                    placeholder="Your Message"
                    className="text-input form-control"
                  ></textarea>
                </div>
              </div>
            </div>
          <div className="programFormInner">
            <div className="row">
              <div className="col-md-6">
                <fieldset className="heightview">
                  <legend>Learning & Development Domain</legend>
                  <ul>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="noetic Science"
                          name="projects[]"
                          onChange={handleDevelopment}
                        />{" "}
                        <span>noetic Science</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="Enablement Program"
                          name="projects[]"
                          onChange={handleDevelopment}
                        />{" "}
                        <span>Enablement Program</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="TT | Cross-Culture | Leadership & Management"
                          name="projects[]"
                          onChange={handleDevelopment}
                        />{" "}
                        <span>
                        Cross-Culture|Leadership & Management
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="Behavioural Skillset Program"
                          name="projects[]"
                          onChange={handleDevelopment}
                        />{" "}
                        <span>Behavioural Skillset Program</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="Coaching"
                          name="projects[]"
                          onChange={handleDevelopment}
                        />{" "}
                        <span>Coaching</span>
                      </label>
                    </li>
                  </ul>
                </fieldset>
              </div>
              <div className="col-md-6">
           
              </div>
            </div>
          </div>
          <div className="programFormInner">
            {/* <div className="form-group col-md-4">
                      <div className="captchatext"> <input id="captcha1" name="captcha" type="text" placeholder="Enter Image Text:*"/>  
                      </div>
                      <div id="imgdiv">
                         <img id={ChapchaImg} / >
                      </div>
                      <img id={Reload} />
                   </div> */}
            <div className="col-md-6 text-align-left">
              <button
                name="send"
                type="submit"
                className="btn btn-default submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
              {/* <input type="hidden" name="submit" value="submit" /> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  )
}

export default LearningDevelopmentForm
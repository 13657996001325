import React, { useState } from 'react';
import '../Styles/BehaviouralMenu.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import BottomCarousel from '../Components/BottomCarousel'
import SkillMenuBar from '../Components/SkillMenuBar';
import LearningDevelopmentForm from '../Components/LearningDevelopmentForm';

function BehaviouralMenu() {
return (
<div className="App">
   <header className="header">
   <section className='backgroung-image-behaviral'>
       <Navbar/>
       {/* <div className='header-about-content'>
         <h1>.</h1>
      </div> */}
      </section>
      {/* menu Start */}
      <SkillMenuBar/>
       {/* Header Menu End */}
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="container">
                     <h5> SERVICES</h5>
                     <h2><span>Behavioral</span> skill program</h2>
                      <div className='row'>
                            <p>Employees need to have specific skills that enable them to face the demands of modern working life. Behaviors are recognized as a major contributor to peoples’ success in business. Organizations are gradually realizing that behavioral competence is highly important to realize and venture towards set goals. Ultimately, it is about how a manager uses his or her knowledge and experience; where does the connection lie with his or her ambition, motivation, and personality? </p>
                            <p>Behavioral Skills Program is an effective knowledge transfer package that consists of instructions, modeling, rehearsal, and feedback. Behavioral skills Program enables individuals to learn skills and practice them. BRiX Program modules address a wide variety of topics and aim to support participants to increase their understanding of their own behavior and the impact it has on others. The participants are then exposed to models, tools, techniques and ideas, which they can apply to their own situatimport BehaviouralMenu from './BehaviouralMenu';
ion. </p>
                            <p>All of our program modules are customized and designed according to the needs and objectives of the organization and its participant employees. All our courses are highly participative with a high emphasis on application and practice of learned skills. The aim of the training is to achieve a change in behavior of the participants. Behavioral skills program is focused on the individual’s performance. At the end of a course each participant will receive a summary of recommendations in order to become even more successful in the future.</p>
                            <p>This means that our coaches will confirm to ICF’s “Code of Ethics” and display in action the “Core Competencies” of a coach.</p>
                            <p>Let’s work together and achieve your organizational goals by “Creating Leaders and Driving Performance through Coaching”!</p>
                      </div>
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
         {/* program form Inner start */}
         <LearningDevelopmentForm/>
{/* program form end */}

      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
</main>
{/* <!------footer start--------> */}
<Footer/>
{/* <!------footer end--------> */}
</div>
);
}
export default BehaviouralMenu;
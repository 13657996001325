import React, {  useState } from 'react'
import '../Styles/Contact.css'
import indiaImg1 from '../assets/images/contact-chennai.jpg'
import indiaImg2 from '../assets/images/contact-bengaluru.jpg'
import indiaImg3 from '../assets/images/contact-hyderabad.jpg'
import indiaImg4 from '../assets/images/contact-mumbai.jpg'
import indiaImg5 from '../assets/images/contact-amaravati.jpg'
import indiaImg6 from '../assets/images/contact-pune.jpg'
import indiaLocationImg from '../assets/images/location-map.jpg'
import { FaEnvelope } from 'react-icons/fa';
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import HappyClients from '../Components/HappyClients'
import BottomCarousel from '../Components/BottomCarousel'
import axios from "axios"

function Contacts() {
   const [name,setName]=useState()
   const [mail,setMail]=useState()
   const [mobile,setMobile]=useState()
   const [message,setMessage]=useState()
   
   const handleSubmit=async()=>{
      await axios.post("http://localhost:5000/api/contact",{name:name,mobile:mobile,to:mail,message:message})
   }
 
// Accordian Action
var action = 'click';
var speed = "500";
// Document Ready

return (
<div className="App">
   <header className="header">
      <section className='backgroung-image-contact'>
         <Navbar/>
         <div className='header-Careers-contents'>
            <h1>YOUR DESNITY</h1>
            <h2>REACH US THIS WAY</h2>
         </div>
      </section>
   </header>
   <main className="main">
      {/* <!--Content--> */}
      <div className="testimonials-bg">
         <div className="container">
            <div className="row">
               <h5>BRiX Network Pvt Ltd</h5>
               <h2><span>CONTACT</span> US</h2>
               <div className='d-flex text-align-center email-address'>
                  <p className="email pl-4"><a href="mailto:info@brixnet.in">< FaEnvelope color="red" size={22} padding={10} />info@brixnet.in</a> | </p>
                  <p className="email">
                     <a href="mailto:sme@brixnet.in">
                        <FaEnvelope color="red" size={22} />
                        sme@brixnet.in
                     </a>
                  </p>
               </div>
            </div>
         </div>
      </div>
         {/* contact form section */}
         <section className='contact-section'>
         <div className="contactForms pb-5 pt-5">
            <div className="container">
               <h3 className='contactForms-heading'>Get In Touch</h3>
               <p>Please drop in your details and we will get in touch with you shortly.</p>
               <div className="careerFormIns">
                  <form >
                     <div className="col-md-6 cfleft">
                        <div className="form-groups">
                           <input type="text" name="Name"  onChange={(e)=>setName(e.target.value)} className="text-input form-control" pattern="[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"  placeholder="Name *" required />
                        </div>
                        <div className="form-groups">
                           <input type="text" name="Email" onChange={(e)=>setMail(e.target.value)} className="text-input form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"placeholder="Email *" required />
                        </div>
                     </div>
                     <div className="col-md-6 cfright">
                        <div className="form-groups">
                           <input type="text" name="Phone" onChange={(e)=>setMobile(e.target.value)} className="text-input form-control" maxlength="10" minlength="10" pattern="[0-9]{10}"  placeholder="Phone *" required />
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="form-groups">
                           <textarea name="Message"  onChange={(e)=>setMessage(e.target.value)} cols="" placeholder="Enter your message"  rows=""></textarea>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="form-groups">
                           <button className='submit-Btn' onClick={handleSubmit} type="submit" class="btn btn-default submit">Submit</button>
                           <input type="hidden" name="submit" value="submit" />
                        </div>
                     </div>
                  </form>
                  
               </div>
            </div>
         </div>
      </section>
      {/* contact form end */}
      {/* India image section start */}
      <section className=' container india-image-section pt-4'>
         <div className="Location-details-page">
            <div className="row">
               <div className="col-md-5">
                  <div className="location-contact">
                     <img src={indiaImg1} alt=""/>
                     <p>CHENNAI<br/></p>
                  </div>
                  <div className="location-contact">
                     <img src={indiaImg2} alt=""/>
                     <p>BENGALURU<br/></p>
                  </div>
                  <div className="location-contact">
                     <img src={indiaImg5} alt=""/>
                     <p>AMARAVATI<br/></p>
                  </div>
                  <div className="location-contact">
                     <img src={indiaImg3} alt=""/>
                     <p>HYDERABAD<br/></p>
                  </div>
                  <div className="location-contact mr-btm">
                     <img src={indiaImg4} alt=""/>
                     <p>MUMBAI<br/></p>
                  </div>
                  <div className="location-contact mr-btm">
                     <img src={indiaImg6} alt=""/>
                     <p>PUNE<br/></p>
                  </div>
               </div>
               <div className="col-md-7">
                  <img src={indiaLocationImg} alt=""  className="map-location"/>
               </div>
            </div>
         </div>
      </section>
      {/* End India section  */}
   
      {/* Happy Clients Start */}
      <section className='Happy-clients'>
         <HappyClients/>
      </section>
      {/* Happy Clients END */}
      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default Contacts;
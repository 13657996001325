import React, { useState } from "react";
import axios from "axios";
import "../Styles/AdvancedTechnology.css";

const AdvancedTechnologyForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    designation: "",
    company: "",
    technology: [],
    domain: [],
    development: [],
    service: [],
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e, field) => {
    if (e.target.checked) {
      setFormData({ ...formData, [field]: [...formData[field], e.target.value] });
    } else {
      setFormData({
        ...formData,
        [field]: formData[field].filter((val) => val !== e.target.value),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:5000/api/learning", formData);
      console.log("Form submitted:", formData);
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <section className="program-form">
      <div className="programForm">
        <div className="container">
          <h2>
            <span>To Know More</span> About The Program
          </h2>
          <p>
            Please fill the following details. One of our subject matter <br />
            experts will revert to you soon.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="programFormInner ">
              {/* <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    placeholder="Name*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="designation"
                    onChange={handleChange}
                    placeholder="Designation*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="company"
                    onChange={handleChange}
                    placeholder="Company"
                    className="text-input form-control"
                    required
                  />
                </div>
              </div> */}
              <div className="">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    placeholder="Name*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="designation"
                    onChange={handleChange}
                    placeholder="Designation*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="company"
                    onChange={handleChange}
                    placeholder="Company"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group ">
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="Email*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group ">
                  <input
                    type="text"
                    name="mobile"
                    pattern="[0-9]{10}"
                    maxLength="10"
                    onChange={handleChange}
                    placeholder="Phone Number*"
                    className="text-input form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                   type="text"
                    name="message"
                    onChange={handleChange}
                    placeholder="Your Message"
                    className="text-input form-control"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="programFormInner row">
              <div className="col-md-6">
                <fieldset>
                  <legend>Advance Technology Domain</legend>
                  <ul>
                    {["Enablement Program", "Vendor neutral Program", "OEMs enablement | Certification Program", "Cloud | Infrastructure automation program"].map((tech) => (
                      <li key={tech}>
                        <label>
                          <input
                            type="checkbox"
                            value={tech}
                            onChange={(e) => handleCheckboxChange(e, "technology")}
                          />
                          <span>{tech}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </fieldset>
              </div>
            </div>

            <div className="programFormInner">
              <div className="col-md-6 text-align-left">
                <button type="submit" className="btn btn-default submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AdvancedTechnologyForm;

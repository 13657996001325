import React from 'react'
import '../Styles/AdvancedTechnology.css'
import BottomCarousel from '../Components/BottomCarousel'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import advacedPracticalImg from '../assets/images/atd-programs.png'
import AdvancedTechnologyform from '../Components/AdvancedTechnologyform'

function AdvancedTechnology() {
  
return (
<div class="App">
   <header class="header">
      <section class='backgroung-image-advancedtechnology'>
         <Navbar/>
         {/* <div class='header-about-content'>
            <h1>.</h1>
         </div> */}
      </section>
   </header>
   <main class="main">
      <section class='container about-success'>
         <div class='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div class="testimonials-bg">
               <div class="container">
                  <div class="row">
                     <h5>OUR SERVICES</h5>
                     <h2><span>ADVANCED</span> TECHNOLOGY DOMAIN</h2>
                     <p>BRiX Networks has partnered with a few of India’s top private, Science, Engineering Universities & Business
                        Schools through its subsidiary company VEDAM Network Academy in association with SoSE™, to upskill student
                        community as part of the industry and academic collaboration initiative, by setting up its Knowledge Transfer
                        Center (KTC), enabled with real presence, connected over Domestic MPLS backhaul. 
                     </p>
                     <p>BRiX Network Pvt Ltd is a pure play Knowledge Transfer Organization, was incorporated in November 2014,
                        with ATP feature from extreme networks for PAN India. BRiX thrives in setting a benchmark in the Knowledge
                        Transfer Domain and its Services by building a strong Business Compliance awareness team on its designated
                        vertical delivery to its clientele, with an objective of ensuring value based, high quality and future-proof
                        services. BRiX Networks has identified and partnered with world’s leading life science organization | OEMs for 
                        their Authorised Training Partner & Authorised Learning Center | Empaneled Subject Matter Experts | Lead auditors 
                        | Cyber Security Experts. 
                     </p>
                     <p>In addition, BRiX would provide Domain Compliance Program and industry best practice services to its associates
                        – System Integrators | Technology Institutions | Distributors | OEMs .
                     </p>
                  </div>
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
      {/* program section start */}
      <section class='program-sections'>
         <div class="thisPractices">
            <div class="container">
               <img src={advacedPracticalImg} alt="ncnbdjcn"/>
            </div>
         </div>
      </section>
      {/* program section end */}
      {/* program form Inner start */}
      <section class='Program-form'>
         {/* <LeaderForm/> */}
         <AdvancedTechnologyform/>
</section>
{/* program form end */}
{/* Brand Section Start */}
<BottomCarousel/>
      {/* Brand Section end */}
</main>
{/* <!------footer start--------> */}
<Footer/>
{/* <!------footer end--------> */}
</div>
);
}
export default AdvancedTechnology;
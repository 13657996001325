import React from 'react'
import icon1 from '../assets/images/domain.png'
import icon2 from '../assets/images/learning.png'
import icon3 from '../assets/images/professional.png'
import icon4 from '../assets/images/advanced.png' 
function FeatureSection() {
return (
<div className="section-padding p-5 text-center">
   <div className="section-contant text-center">
      <div className="features-grid">
         <div className="feature">
            <div className="feature-icon">
               <img src={icon4} alt="Open API Icon" />
            </div>
            <div className="feature-title">
               <h3>Advance Technology Domain</h3>
            </div>
            <div className="feature-description">
               <p>
               Premium knowledge transfer on advance technology, enablement programs,vendor neutral programs, OEM's enablement & certification programs, CLOUD, SDx, Infrastructure automation tools, learning & development domain
               </p>
               <a href="advanced-technology-domain"className="custom-button" >Read More</a>
            </div>
         </div>
         <div className="feature">
            <div className="feature-icon">
               <img src={icon2} alt="Simple Budgeting Icon" />
            </div>
            <div className="feature-title">
               <h3>Learning & Development Domain</h3>
            </div>
            <div className="feature-description">
               <p>Enabling potential minds and it's creativity through fostering programs on noetic science, leadership & management,coaching, business skills, behavioural skills and croos culture</p>
               <a href="noetc-science"className="custom-button" >Read More</a>
            </div>
         </div>
         <div className="feature">
            <div className="feature-icon">
               <img src={icon1} alt="Fast Onboarding Icon" />
            </div>
            <div className="feature-title">
               <h3>Domain Compliances & Governance</h3>
            </div>
            <div className="feature-description">
               <p>To validate "RESKILL TO STAY RELEVANT" in the evolving world of applied science, we render services for project management and expert, ITIL-SERIES, JIRA Project management, CEM, CISA, CISM, CISSP, certified dta centre professional and specialist and certified IT manage </p>
               <a href="domain-compliances-gov"className="custom-button" >Read More</a>
            </div>
         </div>
         <div className="feature">
            <div className="feature-icon">
               <img src={icon3} alt="Open API Icon" />
            </div>
            <div className="feature-title">
               <h3>Professional Service Domain</h3>
            </div>
            <div className="feature-description">
               <p>The magnitude maximizes to render services on Infosec, VA/PT, mobile security scanning and reporting, baseline auditing, IT infrastructure auditing, readiness auditing, comprehensive auditing, knowledge management services, project management & implementation program 
               </p>
            <a href="professional-service-domain"className="custom-button" >Read More</a>
            </div>
         </div>
      </div>
   </div>
</div>
);
}
export default FeatureSection;
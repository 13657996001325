import React from 'react'
import '../Styles/ProfessionalService.css'
import BottomCarousel from '../Components/BottomCarousel'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import DomianIMg from '../assets/images/psd-img.jpg'
import Form from '../Components/form'
import NewForm from '../Components/NewForm'
// import Reload from '../assets/images/reload.png';
// import ChapchaImg from '../assets/images/captcha.png'
import LeaderForm from '../Components/LearningForm';
import ProfessionalServiceForm from '../Components/ProfessionalServiceForm'

function ProfessionalService() {
  
// Accordian Action
var action = 'click';
var speed = "500";

return (
<div className="Apsp">
   <header className="header">
      <section className='backgroung-image-profesional-domain'>
         <Navbar/>
         {/* <div className='header-Careers-contents'>
            <h1>Delivered by Compliance</h1>
            <h2>Awareness Team</h2>
         </div> */}
      </section>
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="container">
                  <div className="row">
                     <h5>SERVICES</h5>
                     <h2><span>Professional </span> service domain</h2>
                     <p>With an unwavering dedication to excellence, we meticulously fuse state-of-the-art business intelligence strategies with rich domain vibrance, meticulously crafting bespoke solutions poised for the future. Our steadfast commitment guarantees the delivery of professional services that transcend the ordinary, empowering our clients to thrive amidst dynamic market shifts with confidence and resilience.</p>
                  </div>
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
      {/* program section start */}
      <section className='program-sections'>
         <div className="thisPracticess">
            <div className="container">
               <img src={DomianIMg} alt="ncnbdjcn"/>
            </div>
         </div>
      </section>
      {/* program section end */}
      {/* program form Inner start */}
      <section className='Program-form'>
       <ProfessionalServiceForm/>
      </section>
      {/* program form end */}
      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default ProfessionalService;
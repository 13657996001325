import React, { useState } from 'react';
import '../Styles/CrossCulture.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import DiagrameImg from '../assets/images/Cross-culture-img.png'
import BottomCarousel from '../Components/BottomCarousel'
import SkillMenuBar from '../Components/SkillMenuBar';
import LearningDevelopmentForm from '../Components/LearningDevelopmentForm';

function CrossCultue() {
 
return (
<div className="App">
   <header className="header">
   <section className='backgroung-image-crossculture'>
       <Navbar/>
       {/* <div className='header-about-content'>
         <h1>Cross - Culture</h1>
      </div> */}
      </section>
          {/* menu Start */}
          <SkillMenuBar/>
       {/* Header Menu End */}
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="container">
                     <h5> SERVICES</h5>
                     <h2><span>Cross</span> - Culture</h2>
                      <div className='row mt-5'>
                      <p>A cross-cultural understanding in today’s world is crucial for making life abroad easier and more successful. Organizations seeking to expand their cultural understanding, develop their cross-cultural skills. Our Trainings are customized for individual needs and addressing topics relevant to organizations seeking to expand their cultural understanding, develop their cross-cultural skills. All cross-cultural trainings are given on demand starts with a cross-cultural assessment. BRiX delivery methodology is inspiring and interactive. Our coaches and trainers mix, exercises and practical advice and empower your employees to be able to understand these differences and how to work across cultures. Our workshops cover a vast number of subjects such as 
                        <span>values, morals, ethics, business practices, etiquette, protocol, negotiation styles and safety issues.</span></p>
                   
                      </div>
                      
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
        {/* program section start */}
        
      <section className='  program-sectionss'>
         <div className=" thisPractices">
            <div className="container">
               <img src={DiagrameImg} alt="ncnbdjcn"/>
            </div>
         </div>
      </section>
      {/* program section end */}
       {/* program form Inner start */}
       <LearningDevelopmentForm/>
{/* program form end */}

      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
</main>
{/* <!------footer start--------> */}
<Footer/>
{/* <!------footer end--------> */}
</div>
);
}
export default CrossCultue;